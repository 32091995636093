<template>
  <div>
    <!-- <div class="FloatSalaryHeader">
      <div class="item">
        考核月份：
        <el-date-picker
          v-model="parameter.examineMonth"
          type="month"
          placeholder="选择考核月份"
          value-format="yyyy-MM"
          @change="examineMonthChange"
        >
        </el-date-picker>
      </div>
    </div> -->
    <div class="FloatSalaryHeader">
      <div class="leftContent">
        <!-- 薪酬说明 -->
        <SalaryExplanation />
      </div>  
      <div class="rightContent">
        <div class="item">
          考核月份：
          <el-date-picker
            v-model="parameter.examineMonth"
            type="month"
            placeholder="选择考核月份"
            value-format="yyyy-MM"
            @change="examineMonthChange"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading">
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column
        align="center"
        prop="userName"
        width="100"
        label="员工姓名"
      />
      <el-table-column
        align="center"
        prop="examineMonth"
        width="100"
        label="考核月份"
      />
      <!-- <el-table-column
        align="center"
        prop="deptName"
        v-if="options.scoreType === 'manage'"
        label="部门名称"
      /> -->
      <el-table-column
        align="center"
        prop="projectName"
        label="项目名称"
      />
      <!-- <el-table-column
        align="center"
        prop="businessName"
        v-if="options.scoreType === 'business'"
        label="商务名称"
      /> -->
      <el-table-column
        align="center"
        prop="scoreRatio"
        label="评分系数"
        width="100"
      />
      <el-table-column align="center" prop="hourSalary" label="工时薪酬" />
    </el-table>
    <el-pagination
      style="margin-top: 5px; text-align: right"
      prev-text="上一页"
      next-text="下一页"
      layout="total, prev, pager, next, slot, jumper"
      :current-page="parameter.pageNow"
      :page-size="parameter.pageSize"
      :total="parameter.total"
      @size-change="handleSizeChange"
      @current-change="pageChangeHandler"
    >
      <span class="el-pagination__jump e_a_pagination">
        <el-input
          size="mini"
          v-model.number="pageSize"
          @blur="handlePageSize"
        ></el-input>
        <span style="padding-top: 1px">条/页</span>
      </span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  components: {
    SalaryExplanation: () => import("../salary/SalaryExplanation.vue"),
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      parameter: {
        scoreType: "project",
        examineMonth: "",
        order: "",
        orderRule: "",
        pageNow: 1,
        pageSize: 10,
        total: 0,
      },
      pageSize: 10,
    };
  },
  watch: {
    options: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val.id) {
            this.parameter.examineMonth = this.getCurrentYearMonth();
            this.getTableData();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getCurrentYearMonth() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth(); 
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    },
    examineMonthChange(val) {
    //   this.parameter.examineMonth = val;
      this.getTableData();
    },
    getTableData() {
      this.loading = true;
      let params = {
        projectId: this.options.id,
      };
      Object.assign(this.parameter, params);
      this.$api.compensationManage
        .staffSalaryDetail(this.parameter)
        .then((res) => {
          //   console.log(res);
        //   for (let i = 0; i < 5; i++) {
        //     this.tableData.push(...res.data.records)
        //   }
          this.tableData = res.data.records;
          this.parameter.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // handleCancel() {
    //   this.$emit("closeEdit", true);
    // },
    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page;
      this.getTableData(); /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val;
      this.parameter.pageNow = 1;
      this.getTableData();
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1;
      }
      this.parameter.pageSize = this.pageSize;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.FloatSalaryHeader {
  display: flex;
  .rightContent {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .item {
    margin-right: 10px;
  } 
}
/deep/.el-table__body-wrapper {
  max-height: 100%;
  overflow: auto;
}
</style>
